import CurrencyList from 'currency-list'

import sortBy from 'lodash.sortby'

export const currencyOptions = sortBy(Object.values(CurrencyList.getAll('en_AU')), ['name']).map(c => {
  return { value: c.code, label: `${c.name} (${c.code})` }
})

export const priorityCurrencyOptions = [
  { value: 'AUD', label: 'Australian Dollar (AUD)' },
  { value: 'NZD', label: 'New Zealand Dollar (NZD)' },
  { value: 'GBP', label: 'Great British Pound (GBP)' },
  { value: 'USD', label: 'United States Dollar (USD)' }
]

export const formatCurrency = (amount: number | null | undefined, currencyCode = 'AUD') => {
  if (amount == null) return

  const format = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: currencyCode
  })

  return format.format(amount)
}
