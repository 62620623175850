import { ReactNode } from 'react'
import classNames from 'classnames/bind'

import Icon from 'components/Icon'
import styles from './Dropdown.module.css'
const cx = classNames.bind(styles)

const DropdownItem = ({
  className,
  icon,
  isSelected = false,
  label,
  caption,
  onClick,
  badge,
  showIsSelectedCheckmark = true
}: {
  className?: string
  icon?: string
  isSelected?: boolean
  label: string | ReactNode
  caption?: string
  onClick: () => void
  badge?: ReactNode
  showIsSelectedCheckmark?: boolean
}) => (
  <div className={cx(styles.dropdownItem, className, { isSelected })} onClick={onClick}>
    {icon && (
      <div className={styles.dropdownItemIcon}>
        <Icon kind={icon} size={14} />
      </div>
    )}
    <span className={styles.dropdownItemLabelWrapper}>
      <span className={styles.dropdownItemLabel}>{label}</span>
      {caption && <small className={styles.dropdownItemCaption}>{caption}</small>}
    </span>
    {badge && <div className={styles.dropdownItemBadge}>{badge}</div>}
    {isSelected && (
      <div className={styles.dropdownItemCheckmark}>
        {showIsSelectedCheckmark ? <Icon kind="check" size={14} /> : null}
      </div>
    )}
  </div>
)

export default DropdownItem
