import React, { useEffect } from 'react'
import Router from 'next/router'
import { useLazyQuery } from '@apollo/client'

import { useUserSession } from 'hooks'
import { DropdownItem, DropdownSection } from 'components/Dropdown'
import Dropdown, { DropdownProps } from 'components/Dropdown/Dropdown'

import GET_ACCOUNT_SIGNUP_STATE from '../FinishSignupButton/graphql/GetAccountSignupState.graphql'
import {
  GetAccountSignupState,
  GetAccountSignupState_currentAccount_accountable_Rep
} from '../FinishSignupButton/graphql/__generated__/GetAccountSignupState'
import { RepKindEnum, AccountableTypeEnum } from '../../../../../../__generated__/globalTypes'

type UserDropdownProps = {
  children?: React.ReactNode
} & Omit<DropdownProps, 'children'>

const UserDropdown = ({ children, ...rest }: UserDropdownProps) => {
  const { isRep, accounts, endSession } = useUserSession()

  const [checkAccountSignupState, { loading, data }] = useLazyQuery<GetAccountSignupState>(GET_ACCOUNT_SIGNUP_STATE)

  useEffect(() => {
    if (isRep) checkAccountSignupState()
  }, [checkAccountSignupState, isRep])

  const toProfile = () => {
    const account = data?.currentAccount

    if (account?.type === AccountableTypeEnum.REP) {
      const rep = account.accountable as GetAccountSignupState_currentAccount_accountable_Rep

      if (rep && rep.kind === RepKindEnum.INDEPENDENT) {
        return Router.push('/rh/profile')
      }
    }

    return Router.push('/profile')
  }

  return (
    <Dropdown {...rest}>
      <DropdownSection>
        {!loading && <DropdownItem icon="user" label="Profile" onClick={toProfile} />}
        {(accounts?.length || 0) > 0 && (
          <DropdownItem icon="shield" label="Accounts" onClick={() => Router.push('/accounts')} />
        )}
        <DropdownItem
          icon="log-out"
          label="Logout"
          data-ref="logout-submenu"
          onClick={async () => {
            await endSession({ redirectTo: '/' })
          }}
        />
      </DropdownSection>

      {children}
    </Dropdown>
  )
}

export default UserDropdown
