import React, { useRef } from 'react'
import classNames from 'classnames/bind'
import useOnClickOutside from 'use-onclickoutside'

import styles from './Dropdown.module.css'
const cx = classNames.bind(styles)

import DropdownHeader from './DropdownHeader'
import DropdownItem from './DropdownItem'
import DropdownSection from './DropdownSection'

export type DropdownProps = {
  className?: string
  children: React.ReactNode
  headerTitle?: string
  headerCaption?: string
  position?: string
  onClickOutside: () => void
}

const Dropdown = ({
  className,
  children,
  headerTitle,
  headerCaption,
  position = 'left',
  onClickOutside
}: DropdownProps) => {
  const ref = useRef(null)
  useOnClickOutside(ref, onClickOutside)

  return (
    <div className={cx(styles.dropdown, className, [position])} ref={ref}>
      {(headerTitle || headerCaption) && <DropdownHeader title={headerTitle as string} caption={headerCaption} />}
      <div className={cx(styles.dropdownInner)}>{children}</div>
    </div>
  )
}

export default Dropdown

export { DropdownItem, DropdownSection }
