import React from 'react'
import classNames from 'classnames/bind'

import Icon from 'components/Icon'

import styles from 'components/Button/Button.module.css'

export type ButtonAnchorProps = {
  icon?: string
  iconColor?: string
  iconSize?: number
  iconPosition?: 'left' | 'right'
  isElastic?: boolean
  disabled?: boolean
  kind?:
    | 'default'
    | 'brand'
    | 'primary'
    | 'positive'
    | 'reverse'
    | 'dark'
    | 'light'
    | 'transparent'
    | 'link'
    | 'danger'
    | 'warning'
    | 'dangerSecondary'
    | 'positiveSecondary'
  size?: 'default' | 'mini' | 'large'
  isBlock?: boolean
  onClick?: React.MouseEventHandler<HTMLAnchorElement> | undefined
  href?: string
  target?: string
  className?: string
  labelStyle?: string
  buttonStyle?: string
  children?: React.ReactNode
}

const ButtonAnchor = React.forwardRef<HTMLAnchorElement, ButtonAnchorProps>(
  (
    {
      icon,
      iconColor = 'currentColor',
      iconPosition = 'left',
      iconSize: iconCustomSize,
      isElastic = false,
      disabled = false,
      kind = 'default',
      size = 'default',
      isBlock = false,
      onClick,
      href,
      target,
      className,
      labelStyle,
      buttonStyle,
      children
    },
    ref
  ) => {
    const hasIconLeft = icon && iconPosition === 'left'
    const hasIconRight = icon && iconPosition === 'right'

    const cx = classNames.bind(styles)
    const classes = cx(className, styles.button, [kind, size], {
      isBlock,
      isElastic,
      disabled,
      hasIconLeft,
      hasIconRight
    })

    const iconSize = iconCustomSize != null ? iconCustomSize : size === 'mini' ? 12 : 16

    return (
      <a
        className={classes}
        target={target}
        rel={target === '_blank' ? 'noopener noreferrer' : ''}
        onClick={onClick}
        href={href}
        ref={ref}>
        <span className={cx(styles.buttonInner, buttonStyle)}>
          {hasIconLeft && <Icon className={styles.icon} kind={icon} color={iconColor} size={iconSize} />}
          {children && <span className={cx(styles.label, labelStyle)}>{children}</span>}
          {hasIconRight && <Icon className={styles.icon} kind={icon} color={iconColor} size={iconSize} />}
        </span>
      </a>
    )
  }
)

ButtonAnchor.displayName = 'ButtonAnchor'

export default ButtonAnchor
