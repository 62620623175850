import React from 'react'
import Link from 'next/link'

import ButtonAnchor from 'components/ButtonAnchor'
import Button, { ButtonProps } from 'components/Button/Button'
import { ButtonAnchorProps } from 'components/ButtonAnchor/ButtonAnchor'

export type ButtonLinkProps = ButtonProps &
  ButtonAnchorProps & {
    href: string
    label: string
  }

const ButtonLink = ({ href, label, disabled, ...rest }: ButtonLinkProps) => {
  if (disabled) {
    return (
      <Button {...rest} disabled={true}>
        {label}
      </Button>
    )
  } else {
    return (
      <Link href={href} passHref>
        <ButtonAnchor {...rest}>{label}</ButtonAnchor>
      </Link>
    )
  }
}

export default ButtonLink
