import React from 'react'
import Link from 'next/link'

type ListItemProps = {
  href: string
  as?: string
  label: string
  isExternal?: boolean
}

type ListProps = {
  className?: string
  links: Array<ListItemProps>
}

const LinkList = ({ className, links }: ListProps) => {
  return (
    <ul className={className}>
      {links.map(link => (
        <li key={link.href}>
          {link.isExternal ? (
            <a href={link.href} target="_blank" rel="noopener noreferrer">
              {link.label}
            </a>
          ) : (
            <Link href={link.href} as={link.as}>
              <a>{link.label}</a>
            </Link>
          )}
        </li>
      ))}
    </ul>
  )
}

export default LinkList
