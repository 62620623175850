import React from 'react'
import styles from './Dropdown.module.css'

const DropdownHeader = ({ title, caption }: { title?: string; caption?: string }) => (
  <header className={styles.header}>
    {title && <span className={styles.headerTitle}>{title}</span>}
    {caption && <small className={styles.headerCaption}>{caption}</small>}
  </header>
)

export default DropdownHeader
