import React from 'react'
import classNames from 'classnames/bind'
import styles from './Badge.module.css'

import Icon from 'components/Icon'

const cx = classNames.bind(styles)

export type BadgeKindOrderStatuses = 'draft' | 'posted' | 'backorder'
export type BadgeKindRequestStatuses = 'approved' | 'requested' | 'rejected' | 'removed' | 'declined' | 'pending'
export type BadgeKindProductLabels = 'new' | 'bestSeller' | 'special' | 'upcoming' | 'soldOut' | 'discontinued'
export type BadgeKindDefault = 'default' | 'info' | 'error' | 'warning' | 'success' | 'brand'
export type BadgeKindOther = 'currency'

export enum BadgeColor {
  gray = 'gray',
  tan = 'tan',
  green = 'green',
  blue = 'blue',
  red = 'red',
  yellow = 'yellow',
  purple = 'purple'
}

export const kindToColorMapping = {
  default: BadgeColor.gray,
  info: BadgeColor.blue,
  error: BadgeColor.red,
  warning: BadgeColor.yellow,
  success: BadgeColor.green,
  brand: BadgeColor.tan,

  currency: BadgeColor.green,

  draft: BadgeColor.red,
  posted: BadgeColor.green,
  backorder: BadgeColor.yellow,

  approved: BadgeColor.green,
  requested: BadgeColor.red,
  pending: BadgeColor.yellow,
  rejected: BadgeColor.gray,
  declined: BadgeColor.gray,
  removed: BadgeColor.gray,

  new: BadgeColor.green,
  bestSeller: BadgeColor.blue,
  special: BadgeColor.red,
  upcoming: BadgeColor.purple,
  soldOut: BadgeColor.yellow,
  discontinued: BadgeColor.gray
}

export type BadgeProps = {
  name?: string
  children: React.ReactNode
  className?: string
  hasMargin?: boolean
  kind?: BadgeKindDefault | BadgeKindOther | BadgeKindOrderStatuses | BadgeKindRequestStatuses | BadgeKindProductLabels
  leadingIcon?: string
  leadingIconColor?: string
  color?: BadgeColor
  style?: 'round' | 'tinted' | 'solid'
  onDismissClick?: () => void
  htmlTitle?: string
}

const Badge = ({
  children,
  className,
  hasMargin,
  kind = 'default',
  style = 'solid',
  leadingIcon,
  leadingIconColor,
  color,
  onDismissClick,
  htmlTitle
}: BadgeProps) => {
  const badgeColor = color || kindToColorMapping[kind]
  return (
    <div className={cx(styles.badge, className, [badgeColor], [style], { hasMargin })} title={htmlTitle}>
      {leadingIcon && <Icon kind={leadingIcon} color={leadingIconColor} size={12} strokeWidth={3} />}
      <div className={cx(styles.badgeLabel, { hasLeadingIcon: leadingIcon })}>{children}</div>
      {onDismissClick && <Icon kind="x" size={10} onClick={() => onDismissClick()} className={styles.dismiss} />}
    </div>
  )
}

export default Badge
